import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import {
  medWrapper,
  H2Lime,
  B1White,
  colors,
  Btn1LimeGreen,
} from "../../../styles/helpers"

import BlueLimeChameleon from "../../Chameleons/BlueLimeChameleon"
import FormInput from "../../Form/FormInput"
import FormTextarea from "../../Form/FormTextarea"
import FormSuccess from "../../Modals/FormSuccess"
import FormSubmit from "../../Modals/FormSubmit"
import FormErrors from "../../Modals/FormErrors"

const ContactFormSection = styled.section`
  position: relative;
  margin-bottom: 10rem;
  padding: 20rem 0 20rem;

  .formBackground {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, ${colors.colorSecondary}, #03101d);
    clip-path: polygon(0 0, 0 100%, 100% 85%, 100% 15%);
    z-index: -1;
  }

  .chameleon {
    position: absolute;
    top: 7.5rem;
    bottom: auto;
    left: 0;
    width: calc(44.7rem / 2);
    height: calc(21.8rem / 2);

    @media (min-width: 768px) {
      top: auto;
      bottom: 40%;
      left: 0;
      width: calc(44.7rem);
      height: calc(21.8rem);
    }
  }

  .wrapper {
    ${medWrapper};
  }

  .content {
    width: 100%;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      width: calc(50%);
      margin-bottom: auto;
    }

    @media (min-width: 1025px) {
      width: calc(50%);
    }

    h2 {
      ${H2Lime};
    }

    p {
      ${B1White};
    }
  }

  .form {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }

    @media (min-width: 1025px) {
      width: calc(50%);
    }

    fieldset {
      padding: 0;
      border: none;
    }

    .formBtn {
      width: 100%;

      button {
        ${Btn1LimeGreen};

        &::disabled {
          background-color: ${colors.greyMed};
          cursor: not-allowed;
        }
      }
    }
  }
`

const submitToWebServer = async (formID, data) => {
  const FORM_POST_URL = `https://konadb.swbcreative.ca/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`
  const config = { headers: { "Content-Type": "multipart/form-data" } }
  const serverResponse = await axios.post(FORM_POST_URL, data, config)
  if (serverResponse.data.status === "mail_sent") {
    return { errors: false, errorMessages: [] }
  } else {
    return { errors: true, errorMessages: serverResponse.data.invalid_fields }
  }
}

const ContactForm = ({ contactForm }) => {
  const [formField, setFormFeilds] = useState({})
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleFieldChange = e => {
    setFormFeilds({ ...formField, [e.target.name]: e.target.value })
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    setFormStatus({
      ...formStatus,
      submitting: true,
    })
    const formDataArray = Object.entries(formField)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })
    const response = await submitToWebServer(382, bodyFormData)
    handleUpdateServerResponse(response)
  }

  const handleUpdateServerResponse = response => {
    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })
    setFormFeilds({})
  }

  const errors = formStatus.errors
  return (
    <ContactFormSection>
      <div className="wrapper">
        <div className="content">
          <div>
            <h2>{contactForm.acf._kon_conform_title}</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: contactForm.acf._kon_conform_content,
            }}
          />
        </div>
        <div className="form">
          <form onSubmit={e => handleFormSubmit(e)}>
            <fieldset disabled={formStatus.submitting}>
              <FormInput
                label="Full Name"
                name="fullName"
                id="fullName"
                type="text"
                required={true}
                errors={errors}
                value={formField.fullName ? formField.fullName : ""}
                handleOnChange={handleFieldChange}
              />
              <FormInput
                label="Organization"
                name="organization"
                id="organization"
                type="text"
                required={true}
                errors={errors}
                value={formField.organization ? formField.organization : ""}
                handleOnChange={handleFieldChange}
              />
              <FormInput
                label="Organization Email"
                name="organizationEmail"
                id="organizationEmail"
                type="email"
                required={true}
                errors={errors}
                value={
                  formField.organizationEmail ? formField.organizationEmail : ""
                }
                handleOnChange={handleFieldChange}
              />
              <FormInput
                label="Phone"
                name="phoneNumber"
                id="phoneNumber"
                type="text"
                required={true}
                errors={errors}
                value={formField.phoneNumber ? formField.phoneNumber : ""}
                handleOnChange={handleFieldChange}
              />
              <FormTextarea
                label="Comment"
                name="comment"
                id="comment"
                required={false}
                rows={12}
                errors={errors}
                value={formField.comment ? formField.comment : ""}
                handleOnChange={handleFieldChange}
              />
            </fieldset>
            <div className="formBtn">
              <button type="submit" disabled={formStatus.submitting}>
                {formStatus.submitting ? "Submitting Your form..." : "submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="chameleon">
        <BlueLimeChameleon />
      </div>
      <div className="formBackground" />
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </ContactFormSection>
  )
}

export default ContactForm
