import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import HowToWork from "../components/TemplateComponets/Contact/HowToWork"
import WhyKona from "../components/TemplateComponets/Contact/WhyKona"
import ContactForm from "../components/TemplateComponets/Contact/ContactForm"

const contact = props => {
  const { seoInfo, howToSteps, whyKona, contactForm } = props.data
  const location = props.location
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <HowToWork howToSteps={howToSteps} />
      <WhyKona whyKona={whyKona} />
      <ContactForm contactForm={contactForm} />
    </Layout>
  )
}

export const contactQuery = graphql`
  query contactPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }
    whyKona: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_why_title
        _kon_why_content
        _kon_why_points {
          point
        }
      }
    }

    howToSteps: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_htw_steps {
          content
        }
      }
    }
    contactForm: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_conform_title
        _kon_conform_content
      }
    }
  }
`

export default contact
